<template>
  <div class="patentInfo" :style="{ height: infoHei }">
    <el-scrollbar>
      <div class="cont flex-start">
        <!-- <div class="patInfo">
          <patInfo></patInfo>
        </div> -->
        <div class="cont-main col999">
          <div class="updateBox flex-between">
            <div class="type">
              <el-radio-group
                v-model="search.in_use"
                @change="programmeTypeCha"
              >
                <el-radio-button label="1">在用</el-radio-button>
                <el-radio-button label="0">停用</el-radio-button>
              </el-radio-group>
            </div>
            <div class="upButtom">
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('dialysisScheme')"
              >
                <i class="fa fa-plus-circle"></i>新增方案
              </el-button>
              <!-- <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('weight')"
              >
                <i class="fa fa-line-chart"></i>干体重调整记录
              </el-button> -->
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('dialysisSchemeTemplateList')"
              >
                <i class="fa fa-list-alt"></i>模板管理
              </el-button>
            </div>
          </div>
          <div class="tables">
            <el-table
              v-loading=""
              ref="singleTableRef"
              highlight-current-row
              :data="tableData"
              size="small"
              @current-change="handleCurrentChange"
              border
              max-height="164tableLoading"
            >
              >
              <el-table-column
                prop="dialysis_type"
                label="透析方案"
              ></el-table-column>
              <el-table-column
                prop="assign_user"
                label="制定人"
              ></el-table-column>
              <el-table-column prop="make_time" label="制定时间" />
              <el-table-column label="使用状态">
                <template #default="scope">
                  {{ scope.row.in_use === 1 ? '在用' : '停用' }}
                </template>
              </el-table-column>
              <el-table-column prop="updated_time" label="修改时间" />
              <el-table-column fixed="right" label="操作" width="200">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click.prevent.stop="dialysisPlan(scope.row)"
                  >
                    <i v-if="scope.row.in_use === 1" class="txIons stop"></i>
                    <i v-else class="txIons enable"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click.prevent.stop="modDialysis(scope.row, '2')"
                  >
                    <i class="txIons copy"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    v-if="scope.row.in_use === 1"
                    @click.prevent.stop="modDialysis(scope.row, '1')"
                  >
                    <i class="txIons modify"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click.prevent.stop="delDialysis(scope.row)"
                  >
                    <i class="txIons del"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <programmeDetails
            v-if="isProgrammeDetails"
            ref="programmeDetailsRef"
          ></programmeDetails>
        </div>
      </div>
    </el-scrollbar>
    <weight v-if="isWeight" ref="weightRef"></weight>
    <dialysisScheme
      v-if="isdDalysisScheme"
      ref="dialysisSchemeRef"
      @refreshDataList="getList()"
    ></dialysisScheme>
    <dialysisSchemeTemplateList
      v-if="isDialysisSchemeTemplateList"
      ref="dialysisSchemeTemplateListRef"
    ></dialysisSchemeTemplateList>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, nextTick } from 'vue'
import { getConstHei, gitDictList, gitDictVal } from '@/utils/tool.js'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { ElMessageBox, ElMessage } from 'element-plus'
// import patInfo from '@/components/patient/info.vue'
import programmeDetails from '@/components/patient/programmeDetails.vue'
import weight from './weight.vue'
import dialysisScheme from './dialysisSchemes.vue'
import dialysisSchemeTemplateList from './dialysisSchemeTemplateList.vue'
export default {
  name: 'programme',
  components: {
    // patInfo,
    weight,
    dialysisScheme,
    programmeDetails,
    dialysisSchemeTemplateList,
  },
  setup() {
    const weightRef = ref(null)
    const dialysisSchemeRef = ref(null)
    const dialysisSchemeTemplateListRef = ref(null)
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      search: {
        patient_id: '',
        in_use: '1',
      },
      programmeType: '1',
      tableData: [],
      tableLoading: false,
      isWeight: false,
      isdDalysisScheme: false,
      isDialysisSchemeTemplateList: false,
      programmeDetailsRef: null,
      isProgrammeDetails: false,
      singleTableRef: null,
      dictList: {},
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
    })

    const route = useRoute()
    onMounted(async () => {
      state.infoHei = getConstHei()
      state.search.patient_id = route.params.id
      await getDict()
      getList()
    })
    const getList = async () => {
      state.tableLoading = true
      let res = await service.post(
        '/api/patient_dialysis/get_patient_dialysis_plan_list',
        state.search
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '100000000',
        '339400000',
        '339500000',
        '119000000',
        '117000000',
        '116000000',
        '157000000',
        '113000000',
        '114000000',
        '115000000',
        '118000000',
      ])
      // nextTick(() => {
      //   state.programmeDetailsRef.getDict(state.dictList)
      // })
    }

    const programmeTypeCha = () => {
      state.isProgrammeDetails = false
      state.singleTableRef.setCurrentRow()
      // state.search.in_use = val
      getList()
    }

    const handleCurrentChange = (row) => {
      if (!row) return
      state.isProgrammeDetails = true
      nextTick(() => {
        state.programmeDetailsRef.initData(row, state.dictList)
      })
    }

    const openWeight = (type) => {
      switch (type) {
        case 'weight':
          state.isWeight = true
          nextTick(() => {
            weightRef.value.initData()
          })
          break
        case 'dialysisScheme':
          state.isdDalysisScheme = true
          nextTick(() => {
            dialysisSchemeRef.value.initData(route.params.id, state.dictList)
          })
          break
        case 'dialysisSchemeTemplateList':
          state.isDialysisSchemeTemplateList = true
          nextTick(() => {
            dialysisSchemeTemplateListRef.value.initData(
              route.params.id,
              state.dictList
            )
          })
          break
      }
    }

    const modDialysis = (row, type) => {
      state.isdDalysisScheme = true
      nextTick(() => {
        dialysisSchemeRef.value.initData(
          route.params.id,
          state.dictList,
          row,
          type
        )
      })
    }
    const dialysisPlan = (item) => {
      let contText = `您确认要${item.in_use === 1 ? '停用' : '启用'}吗？`
      ElMessageBox.confirm(contText, '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let url = ''
          if (item.in_use === 1) {
            url = '/api/patient_dialysis/disable_patient_dialysis_plan'
          } else {
            url = '/api/patient_dialysis/enable_patient_dialysis_plan'
          }
          let res = await service.post(url, {
            patient_dialysis_plan_id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getList()
          }
        })
        .catch(() => {})
    }
    const delDialysis = (item) => {
      ElMessageBox.confirm('您确认要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post(
            '/api/patient_dialysis/delete_patient_dialysis_plan',
            {
              patient_dialysis_plan_id: item.id,
            }
          )
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getList()
          }
        })
        .catch(() => {})
    }

    return {
      ...toRefs(state),
      weightRef,
      openWeight,
      dialysisSchemeRef,
      dialysisSchemeTemplateListRef,
      gitDictVal,
      programmeTypeCha,
      handleCurrentChange,
      dialysisPlan,
      getList,
      modDialysis,
      delDialysis,
    }
  },
}
</script>

<style scoped lang="scss">
.patentInfo {
  margin-top: 8px;
}
.podBot {
  padding-bottom: 30px;
}
.patInfo {
  width: 220px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 20px;
  margin-right: 32px;
}
.cont-main {
  flex: 1;
}
.updateBox {
  height: 32px;
  margin-bottom: 16px;
}
.tables {
  margin-bottom: 40px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
</style>
