<template>
  <div class="patentDialysis">
    <el-tabs
      v-model="tagName"
      type="card"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="透析方案" name="programme">
        <programme></programme>
      </el-tab-pane>
      <el-tab-pane label="血管通路" name="vascularAccess">
        <vascularAccess></vascularAccess>
      </el-tab-pane>
      <!-- <el-tab-pane label="患者信息">患者信息</el-tab-pane>
      <el-tab-pane label="患者信息">患者信息</el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { ref } from 'vue'
import programme from './dialysis/programme.vue'
import vascularAccess from './dialysis/vascularAccess/vascularAccess.vue'
export default {
  name: 'patentCase',
  components: {
    programme,
    vascularAccess,
  },
  setup() {
    const tagName = ref('programme')

    const handleClick = () => {
      // console.log(tab)
    }

    return {
      tagName,
      handleClick,
    }
  },
}
</script>

<style scoped lang="scss"></style>
