<template>
  <div class="vascularAccessImg">
    <div class="img-box">
      <div class="img-item" v-for="item in tableData" :key="item.id">
        <div class="item-top flex-between">
          <div class="flex-start">
            <div class="info-item">
              <span class="label">日期：</span>
              <span class="val">{{ item.upload_time }}</span>
            </div>
            <div class="info-item">
              <span class="label">上传人：</span>
              <span class="val">{{ item.doctor.name }}</span>
            </div>
            <div class="info-item ellipsis">
              <span class="label">备注：</span>
              <span class="val">{{ item.remark }}</span>
            </div>
          </div>
          <div class="img-but">
            <el-button
              class="color-vice"
              type="primary"
              @click="modAccessImgs(item)"
            >
              <i class="fa fa-edit"></i>修改
            </el-button>
            <el-button
              class="color-red"
              @click="delAccessImg(item)"
              :loading="delLoading"
              type="primary"
            >
              <i class="fa fa-trash-o"></i>删除
            </el-button>
          </div>
        </div>
        <div class="img-cont">
          <el-image
            v-for="(item2, index2) in item.photo"
            :key="index2"
            :preview-src-list="item.srcList"
            :initial-index="index2"
            :src="item2.url"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="pagination-box" v-if="tableData.length > 10">
      <el-pagination
        v-model:currentPage="pageData.currentPage"
        v-model:page-size="pageData.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.totalSum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <modAccessImg
      v-if="isModVascularAccess"
      ref="modVascularAccessRef"
      @refreshDataList="getDataList()"
    ></modAccessImg>
  </div>
</template>
<script>
import { ref, reactive, toRefs, nextTick, onMounted } from 'vue'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import modAccessImg from './modAccessImg.vue'
import { clone } from '@/utils/tool'
export default {
  name: 'vascularAccessImg',
  components: {
    modAccessImg,
  },
  setup() {
    const modVascularAccessRef = ref(null)
    const state = reactive({
      searchData: {},
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      isModVascularAccess: false,
      delLoading: false,
    })

    const route = useRoute()
    onMounted(() => {
      getDataList()
    })
    const getDataList = async () => {
      state.searchData.patient_id = route.params.id
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      let res = await service.post(
        '/api/patient_tonglu/get_tonglu_photo_list',
        state.searchData
      )
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
        state.tableData.map((item) => {
          let srcList = []
          if (item.photo) {
            item.photo.map((row) => {
              srcList.push(row.url)
            })
          }
          item.srcList = srcList
        })
      }
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }

    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const modAccessImgs = (item) => {
      state.isModVascularAccess = true
      let row = clone(item)
      nextTick(() => {
        modVascularAccessRef.value.initData(row)
      })
    }
    const delAccessImg = (item) => {
      ElMessageBox.confirm('您确定要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          state.delLoading = true
          let res = await service.post(
            '/api/patient_tonglu/delete_tonglu_photo',
            {
              tonglu_photo_id: item.id,
            }
          )
          state.delLoading = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }

    const handleRequest = (param) => {
      let fileObj = param.file
      // FormData 对象
      let form = new FormData()
      // 文件对象
      form.append('file', fileObj)
      form.append('resourceId', this.resourceId)
    }

    return {
      ...toRefs(state),
      modVascularAccessRef,
      modAccessImgs,
      handleRequest,
      handleSizeChange,
      handleCurrentChange,
      getDataList,
      delAccessImg,
    }
  },
}
</script>

<style scoped lang="scss">
.vascularAccessList {
  position: relative;
}
.item-top {
  margin-bottom: 20px;
  .el-button {
    margin-left: 15px;
  }
  .info-item {
    margin-right: 30px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Regular';
    line-height: 40px;
    max-width: 300px;
    .label {
      color: #444444;
      margin-right: 16px;
    }
    .val {
      color: #999999;
    }
  }
}
.img-cont {
  .el-image {
    width: 160px;
    height: 160px;
    border-radius: 16px 16px 16px 16px;
    border: 2px solid #e4e7ed;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
