<template>
  <div class="vascularAccess" :style="{ height: infoHei }">
    <el-scrollbar>
      <div class="cont flex-start">
        <!-- <div class="patInfo">
          <patInfo></patInfo>
        </div> -->
        <div class="cont-main">
          <div class="list-but">
            <template v-if="tagName === 'list' || tagName === 'img'">
              <el-button
                class="color-vice"
                type="primary"
                @click="addAccessList(tagName)"
              >
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </template>
          </div>
          <el-tabs type="card" v-model="tagName" @tab-click="handleClick">
            <el-tab-pane label="通路信息" name="info">
              <vascularAccessInfo ref="infoRef"></vascularAccessInfo>
            </el-tab-pane>
            <el-tab-pane label="通路列表" name="list">
              <list ref="listRef"></list>
            </el-tab-pane>
            <el-tab-pane label="通路照片" name="img">
              <accessImg ref="accessImgRef"></accessImg>
            </el-tab-pane>
            <el-tab-pane label="穿刺信息" name="puncture">
              <puncture ref="punctureRef"></puncture>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import { ref, reactive, onMounted, toRefs } from 'vue'
import { getConstHei } from '@/utils/tool.js'
// import patInfo from '@/components/patient/info.vue'
import vascularAccessInfo from './info.vue'
import list from './list.vue'
import accessImg from './accessImg.vue'
import puncture from './puncture.vue'
export default {
  name: 'vascularAccess',
  components: {
    // patInfo,
    vascularAccessInfo,
    list,
    accessImg,
    puncture,
  },
  setup() {
    const tagName = ref('info')
    const state = reactive({
      infoHei: '',
      infoRef: null,
      listRef: null,
      accessImgRef: null,
      punctureRef: null,
    })

    onMounted(() => {
      state.infoHei = getConstHei()
    })

    const handleClick = () => {
      if (tagName.value === 'list') {
        state.listRef.getDataList()
      } else if (tagName.value === 'info') {
        state.infoRef.gitList()
      } else if (tagName.value === 'img') {
        state.accessImgRef.getDataList()
      } else if (tagName.value === 'puncture') {
        state.punctureRef.getDataList()
      }
    }

    const addAccessList = (type) => {
      switch (type) {
        case 'list':
          state.listRef.addAccessList()
          break
        case 'img':
          state.accessImgRef.modAccessImgs()
          break
      }
    }

    return {
      ...toRefs(state),
      tagName,
      handleClick,
      addAccessList,
    }
  },
}
</script>

<style scoped lang="scss">
.vascularAccess {
  margin-top: 10px;
  .el-tabs {
    &:deep(.el-tabs__header) {
      border: 0;
      margin-bottom: 40px;
    }
    &:deep(.el-tabs__item) {
      border-color: #3166ae;
      transition: initial;
      color: #3166ae;
      height: 32px;
      line-height: 32px;
      &.is-active {
        background: #3166ae;
        color: #ffffff;
      }
    }
    &:deep(.el-tabs__header .el-tabs__nav) {
      border-color: #3166ae;
    }
  }
}
.patInfo {
  width: 220px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 20px;
  margin-right: 32px;
}
.cont-main {
  flex: 1;
  width: calc(100% - 252px);
  position: relative;
  .list-but {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
