<template>
  <div class="vascularAccessInfo flex-start">
    <div class="info-img"></div>

    <div class="info-cont">
      <template v-if="list.id">
        <div class="tit">
          <span class="name">{{ list.tonglu_position }}</span>
          <span class="tip">{{ list.tonglu_type }}</span>
        </div>
        <div class="info-item flex-between">
          <div class="lable">建立日期：</div>
          <div class="value">{{ list.make_time }}</div>
        </div>
        <div class="info-item flex-between">
          <div class="lable">启用日期：</div>
          <div class="value">{{ list.enable_time }}</div>
        </div>
        <div class="info-item flex-between">
          <div class="lable">手术者：</div>
          <div class="value">{{ list.doctor ? list.doctor.name : '' }}</div>
        </div>
        <div class="info-item flex-between">
          <div class="lable">科室：</div>
          <div class="value">{{ list.department_id_name }}</div>
        </div>
        <div class="info-item flex-between col">
          <div class="lable">使用状态：</div>
          <div class="value">{{ list.enable === 1 ? '在用' : '停用' }}</div>
        </div>
      </template>
      <template v-else>
        <div class="noData"></div>
      </template>
      <div class="footer">
        <el-button
          class="color-main"
          size="large"
          type="primary"
          @click="openVascularAccess"
        >
          {{ list.id ? '编辑' : '新增' }}
        </el-button>
      </div>
    </div>
    <modVascularAccess
      v-if="isModVascularAccess"
      ref="modVascularAccessRef"
      @refreshDataList="gitList()"
    ></modVascularAccess>
  </div>
</template>
<script>
import { ref, reactive, toRefs, nextTick, onMounted } from 'vue'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
import modVascularAccess from './modVascularAccess.vue'
export default {
  name: 'vascularAccessInfo',
  components: {
    modVascularAccess,
  },
  setup() {
    const modVascularAccessRef = ref(null)
    const state = reactive({
      list: {},
      isModVascularAccess: false,
    })

    onMounted(() => {
      gitList()
    })
    const route = useRoute()
    const gitList = async () => {
      let res = await service.post('/api/patient_tonglu/get_current_tonglu', {
        patient_id: route.params.id,
      })
      if (res.code === 0) {
        state.list = res.data
      }
    }

    const openVascularAccess = () => {
      state.isModVascularAccess = true
      nextTick(() => {
        modVascularAccessRef.value.initData({
          ...state.list,
        })
      })
    }

    return {
      ...toRefs(state),
      modVascularAccessRef,
      openVascularAccess,
      gitList,
    }
  },
}
</script>

<style scoped lang="scss">
.info-img {
  width: 400px;
  height: 400px;
  margin-right: 50px;
}
.info-cont {
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 16px;
  width: 340px;
  height: 360px;
  margin: 2px 0;
  .tit {
    height: 72px;
    background: #3166ae;
    border-radius: 16px 16px 0px 0px;
    color: #ffffff;
    font-family: 'Source Han Sans CN-Medium';
    line-height: 72px;
    .name {
      font-size: 18px;
      margin-left: 16px;
      margin-right: 10px;
    }
    .tip {
      font-size: 14px;
      font-family: 'Source Han Sans CN-Regular';
    }
  }
  .info-item {
    margin-top: 16px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Medium';
    .lable {
      color: #999999;
    }
    .value {
      color: #444444;
    }
    &.col .value {
      color: #70d240;
    }
  }
}
.noData {
  height: 254px;
}
.footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 16px;
  margin-top: 16px;
  .el-button {
    width: 100%;
  }
}
</style>
